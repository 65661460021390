import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import { SprintProvider } from "./SprintContext";
import { supabase } from "./supabase";

import Live from "./Pages/Live";
import Tags from "./Pages/Tags";
import Home from "./Pages/Home";
import Sprint from "./Pages/Sprint";
import Settings from "./Pages/Settings";

import './App.css';

export const SITE_TAG = 'ghent_eddymerckx';
export const SCREEN_MAP = {
    'live': '/',
    'home': '/home',
    'sprint': '/sprint',
};

const mapScreenTag = (screenTag) => Object.keys(SCREEN_MAP).includes(screenTag) ? SCREEN_MAP[screenTag] : '/';

function App() {
    const navigate = useNavigate();
    const location = useLocation();

    const [screenTag, setScreenTag] = useState('/');

    useEffect(() => {
        const fetchScreenConfig = async () => {
            const { data, error } = await supabase.from("screen_configuration").select("* WHERE site = $1", SITE_TAG);

            if (error) {
                console.error(error);
                return;
            }

            if (data.length === 0) {
                console.error("No screen configuration found for site", SITE_TAG);
                return;
            }

            setScreenTag(mapScreenTag(data[0].screen_tag));
        };

        fetchScreenConfig();
    }, [])

    useEffect(() => {
        const taskListener = supabase.channel('screen_configuration').on(
            "postgres_changes",
            { event: "UPDATE", schema: "public", table: "screen_configuration" },
            (payload) => {
                const { site, screen_tag: screenTag } = payload.new;

                if (site !== SITE_TAG) return;
                setScreenTag(mapScreenTag(screenTag));
            },
        ).subscribe();

        return () => taskListener.unsubscribe();
  }, [navigate]);

    useEffect(() => {
        if (!['/settings','/tags','/sprint_fixed'].includes(location.pathname)) {
          navigate(screenTag);
        }
    }, [navigate, screenTag, location.pathname]);

  return (
  <div className="App">
      <main style={{ width: "100%" }}>
      <Routes>
        <Route path="/" element={<Live />}/>
        <Route path="/tags" element={<Tags />} />
        <Route path="/home" element={<Home />} />
        <Route path="/sprint" element={<SprintProvider><Sprint /></SprintProvider>} />
        <Route path="/settings" element={<Settings />}/>

        <Route path="/sprint_fixed" element={<SprintProvider><Sprint /></SprintProvider>} />
      </Routes>
      </main>
     
    </div>
  );
}

//

export default App;
