import { useEffect, useState, useCallback, useRef, createContext, useContext } from "react";
import { supabase } from "./supabase";

export const FINISH_TAG = 'L01';
export const loops = Array.from({ length: 7 }, (_, i) => `L0${i + 1}`);

const INVALIDATION_TIME_MS = 20000;
const MAX_ALLOWED_LAP_TIME_S = 60; 

export const loopDistances = {
    L01: 0,
    L02: 35,
    L03: 50,
    L04: 107,
    L05: 150,
    L06: 160,
    L07: 232
}

const dictDiff = (dict1, dict2) => {
    const changed = {};
    for (const key in dict1) {
        if (dict1[key] !== dict2[key]) {
            changed[key] = [dict1[key], dict2[key]];
        }
    }
    return changed;
};

const SprintContext = createContext();

const SprintProvider = ({ children }) => {
    const [laps, setLaps] = useState([]);
    const [lapBuilder, setLapBuilder] = useState({});
    const [fastestLap, setFastestLap] = useState(null);
    const [lastUpdate, setLastUpdate] = useState({});
    const [activeRiders, setActiveRiders] = useState({});
    const [tags, setTags] = useState([]);

    const lastUpdateRef = useRef(lastUpdate);
    const lapBuilderRef = useRef(lapBuilder);

    const postgresChangesCallback = useCallback((payload) => {
        const { new: record } = payload;
        const { tag: tagname, location: updatedLoop } = record;

        //if (tagname !== 'SK16113') return;
        //if (!tagname.startsWith('S')) return;

        const updatedRtcTime = record[`${updatedLoop}.rtcTime`];

        if (tagname in lastUpdateRef.current) {
            const diff = dictDiff(lastUpdateRef.current[tagname], record);
            if ('location' in diff) {
                console.log(`[${tagname}] Changed location from ${diff.location[0]} to ${diff.location[1]}`);

                const d = diff.location.map((x, i) => {
                    // This can probably be done more generic
                    if (x === 'L01' && i === 0) return 0;
                    if (x === 'L01' && i === 1) return 250;
                    return loopDistances[x];
                });

                const distance = d[1] - d[0];
                const time = (updatedRtcTime - lastUpdateRef.current[tagname][`${diff.location[0]}.rtcTime`]) / 1000;
                const speed = distance / time * 3.6;

                setActiveRiders((prev) => ({ ...prev, [tagname]: { speed, time: new Date() } }));
            }

            if (updatedLoop === FINISH_TAG) {
                finalizeLap(tagname, updatedRtcTime);
            } else {
                setLapBuilder((prev) => ({
                    ...prev,
                    [tagname]: {
                        ...prev[tagname],
                        [updatedLoop]: updatedRtcTime,
                    },
                }));
            }
        } else if (updatedLoop === FINISH_TAG) {
            setLapBuilder((prev) => ({
                ...prev,
                [tagname]: {
                    start: updatedRtcTime,
                    [updatedLoop]: updatedRtcTime,
                },
            }));
        }

        setLastUpdate({
            ...lastUpdateRef.current,
            [tagname]: record,
        });

        
    }, [lastUpdate]);

    const finalizeLap = (tagname, updatedRtcTime) => {
        const lapData = { ...lapBuilderRef.current[tagname], [FINISH_TAG]: updatedRtcTime };

        if (loops.every((loop) => lapData[loop])) {
            const newLap = {
                tag: tagname,
                start: lapData.start,
                ...loops.reduce((acc, loop) => {
                    acc[loop] = Math.round((lapData[loop] - lapData.start)) / 1000;
                    return acc;
                }, {}),
            };

            if (newLap[FINISH_TAG] < MAX_ALLOWED_LAP_TIME_S) {
                supabase.from('laps').insert({ ...newLap, start: new Date(newLap.start).toISOString() }).then(console.log);
                setLaps((prev) => [...prev, newLap]);
            }
        }

        setLapBuilder((prev) => ({
            ...prev,
            [tagname]: { 
                start: updatedRtcTime 
            } 
        }));
    };

    const findTagMapping = (tag) => {
        const mapping = tags.find((t) => t.tag === tag);
        return mapping ? mapping.name : tag;    
    }

    useEffect(() => {
        const taskListener = supabase.channel('laptimes').on(
            "postgres_changes",
            { event: "*", schema: "public", table: "laptimes" },
            (payload) => postgresChangesCallback(payload),
        ).subscribe();

        return () => taskListener.unsubscribe();
    },[]);

    useEffect(() => {
        lastUpdateRef.current = lastUpdate;
    }, [lastUpdate])

    useEffect(() => {
        lapBuilderRef.current = lapBuilder;
    }, [lapBuilder])

    useEffect(() => {
        if (laps.length === 0) return;

        const newLap = laps[laps.length - 1];
        //supabase.from('laps').insert({ ...newLap, start: new Date(newLap.start).toISOString() });

        // Ignore start signal
        if (newLap.tag === '9992') return;

        if (fastestLap === null || newLap[FINISH_TAG] < laps[fastestLap][FINISH_TAG]) {
            setFastestLap(laps.length - 1);
        }
    }, [laps.length])

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveRiders((prev) => Object.fromEntries(
                Object.entries(prev).filter(([tagname, { time }]) => ((new Date()) - time) < INVALIDATION_TIME_MS)
            ));
        }, 2000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const fetchTags = async () => {
            const { data, error } = await supabase.from("tags").select("*");
            setTags(data || []);
        };

        const fetchLaps = async () => {
            const { data, error } = await supabase.from("laps").select("*");
            const mappedData = data.map(lap => ({
                ...lap,
                start: new Date(lap.start).getTime(),
            }));

            setLaps(mappedData || []);

            // determine fastest lap from database
            let flap = null;
            for(let i = 0; i < mappedData.length; i++) {
                const lap = mappedData[i];
                if(flap !== null)
                    console.log(lap[FINISH_TAG], mappedData[flap][FINISH_TAG])
                if (flap === null || lap[FINISH_TAG] < mappedData[flap][FINISH_TAG]) {
                    flap = i;
                }
               
            }

            setFastestLap(flap);
        }

        fetchTags();
        fetchLaps();
    }, []);

    return <SprintContext.Provider value={{ laps, fastestLap, lapBuilder, activeRiders, findTagMapping }}>{children}</SprintContext.Provider>;
}

export { SprintProvider, SprintContext };