import React, { memo, useEffect, useState, useContext } from 'react';
import { FINISH_TAG, loopDistances, SprintContext } from '../../SprintContext';

const SprintTimer = memo(({ athleteLap, referenceLap, tag }) => {
    const [timer, setTimer] = useState(0);
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const [difference, setDifference] = useState(0);
    const [distance, setDistance] = useState(0);

    const { findTagMapping } = useContext(SprintContext);

    const hours = Math.floor(timer / 360000);
    const minutes = Math.floor((timer % 360000) / 6000);
    const seconds = Math.floor((timer % 6000) / 100);
    const milliseconds = timer % 1000;

    const latestKey = Object.keys(athleteLap).filter(x => x.startsWith('L')).sort().reverse()[0];

    const startAndStop = () => setIsTimerRunning(!isTimerRunning);
    const reset = () => {
        setTimer(0); 
        //setDifference(0);
        //setDistance(0);
    }

    useEffect(() => {
        let intervalId;
        if (isTimerRunning) {
            intervalId = setInterval(() => setTimer((timer) => timer + 1), 10);
        }
        return () => clearInterval(intervalId);
    });

    useEffect(() => {
        if (!('start' in athleteLap)) return;
        const keys = Object.keys(athleteLap);

        if (keys.length === 1) {
            reset();
            setIsTimerRunning(true);
        } else if (referenceLap !== null){
            if(["L01","L03", "L05"].includes(latestKey)) {
                console.log(latestKey)
                const difference = (Math.round(((athleteLap[latestKey] - athleteLap.start) / 1000 - referenceLap[latestKey]) * 1000) / 1000).toFixed(3);
                const distance = loopDistances[latestKey];
                setDifference(difference);
                setDistance(distance);
            }
        }
    }, [athleteLap])

    useEffect(() => {
        reset();
        setIsTimerRunning(latestKey === FINISH_TAG);
        /*if(latestKey === FINISH_TAG){       
            const difference = (Math.round(((athleteLap[FINISH_TAG] - athleteLap.start) / 1000 - referenceLap[FINISH_TAG]) * 1000) / 1000).toFixed(3);
            const distance = loopDistances[FINISH_TAG];
            setDifference(difference);
            setDistance(distance);
        }*/
    }, [tag]);

    return (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "left" }}>
            <div style={{ flex: 0.8, textAlign: "left", marginLeft: '50px', minWidth: 0, fontSize: "4rem", alignItems: "left", display: "flex", flexDirection: "column" }}>
                <div>Athlete:</div>
                <b style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '100%', alignSelf: 'left', flex: 1, display: 'flex', justifyContent: 'left' }}>
                    {findTagMapping(tag)}
                </b>
            </div>
            <div style={{ fontSize: "12rem", textAlign: "center", flex: "0 1 auto", lineHeight: "10rem", marginTop: "10px"}}>
                {hours > 0 && <>{hours}:</>}{(minutes>0||hours>0) && (minutes.toString().padStart(2, "0")+":")}
                {seconds.toString().padStart(2, "0")}.
                {milliseconds.toString().padStart(3, "0")}
            </div>
            <div style={{ flex: 1, textAlign: "left", marginLeft: "10px", fontSize: "5rem", paddingTop: "47px"}}>
                {(difference !== undefined && difference !== 0 && isTimerRunning) && (
                    <span style={{ borderRadius: 5 ,background: difference > 0 ? "red" : "green", padding: '0.5rem' }}>
                        {difference > 0 ? '+' : ''}{difference} ({distance}m)
                    </span>
                )}
            </div>
        </div>
    );
});

export default SprintTimer;