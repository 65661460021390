import React, { useState, useEffect } from "react";

const Canvas = ({ children, showTime }) => {

    const [rssTitles, setRssTitles] = useState([]);
    const [currentTime, setCurrentTime] = useState("");

    useEffect(() => {
        if (!showTime) return;

        // Update clock every second
        const interval = setInterval(() => {
            const now = new Date();
            const formattedTime = now.toLocaleTimeString("en-GB", { hour12: false }); // HH:MM:SS format
            setCurrentTime(formattedTime);
        }, 500);

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, []);

    return (
        <div className="live">
            <div
                className="header"
                style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0px 8px",
                }}
            >
                <h1 style={{ margin: "0px" }}><span style={{color : "#006fb7"}}>SPORT.VLAANDEREN</span> <span style={{color : "#38b3e8"}}>GENT</span></h1>
                {showTime && <h1 style={{ margin: "0px",  color: "#363635" }}>{currentTime}</h1>}
            </div>

            {children}
        </div>
    )
}

export default Canvas;