import { useContext, useMemo } from "react";
import { SprintContext, FINISH_TAG } from '../SprintContext';
import SprintTable from "../Components/sprint/SprintTable";
import SprintTimer from "../Components/sprint/SprintTimer";
import Canvas from "../Components/Canvas";
import './Sprint.css';

const targetTag = 'SK16113';

const aggregateFastest = true;
const focusFastest = true;

const getTagWithMaxSpeed = (activeRiders) => Object.entries(activeRiders).reduce((maxEntry, currentEntry) => {
    const [currentTag, currentData] = currentEntry;
    const [, maxData] = maxEntry;

    return currentData.speed > maxData.speed ? currentEntry : maxEntry;
  }, ['', { speed: -Infinity }]
)[0];

const Sprint = () => {
    const { laps, fastestLap, lapBuilder, activeRiders } = useContext(SprintContext);

    const processedLaps = useMemo(() => !aggregateFastest ? laps : Object.values(laps.reduce((acc, lap) => {
        const { tag } = lap;
        if (!acc[tag] || acc[tag][FINISH_TAG] > lap[FINISH_TAG]) {
            acc[tag] = lap;
        }

        return acc;
    }, [])), [laps]);

    const focusedRiderTag = !focusFastest ? targetTag : getTagWithMaxSpeed(activeRiders);
    const targetedLap = lapBuilder[focusedRiderTag] || null;
    const referenceLap = useMemo(() => fastestLap !== null ? laps[fastestLap] : null, [fastestLap]);

    const latestLap = laps.length > 0 ? laps[laps.length - 1] : null;
    const sortedArray = [...laps].sort((a, b) => a[FINISH_TAG] - b[FINISH_TAG]);
    const rankingIndex = laps.length > 0 ? sortedArray.findIndex(item => item.tag === latestLap.tag && item.start === latestLap.start) + 1 : -1;

    const showSprintTimer = targetedLap !== null && focusedRiderTag !== '';

    return (
        <Canvas showTime>
            <div style={{ display: 'flex', flexDirection: 'column', height: '90%' }}>
                {showSprintTimer && <>
                    <div style={{ flex: '0 0 auto' }}>
                        <SprintTimer athleteLap={targetedLap} referenceLap={referenceLap} tag={focusedRiderTag} />
                        <SprintTable laps={laps.length > 0 ? [latestLap] : []} singular={rankingIndex} />
                    </div>
                </>}
                <div style={{ flex: '1', maxHeight: '100%', overflow: 'hidden' }}>
                    <SprintTable laps={processedLaps} fullSize={!showSprintTimer} forceRender={showSprintTimer} />
                </div>
            </div>
        </Canvas>
    );
};

export default Sprint;