import React, { useState, useEffect } from "react";
import { supabase } from "../supabase"; // Import Supabase client
import "./Home.css";

const Home = () => {
  const [lapTimes, setLapTimes] = useState([]);
  const [eventFilter, setEventFilter] = useState("first_passage"); // Default event filter
  const [tags, setTags] = useState([]); // For storing tags with names and categories
  const [selectedTags, setSelectedTags] = useState([]); // For selected tags
  const [selectedCategories, setSelectedCategories] = useState([]); // For selected categories
  const [filters, setFilters] = useState({ tag: "", name: "", category: "" });
  const [visibleColumns, setVisibleColumns] = useState({
    corner_split: true,
    straight_split: true,
    sprint_split: true,
  });

  useEffect(() => {

    const fetchTags = async () => {
      const { data, error } = await supabase.from("tags").select("*");
      if (error) {
        console.error("Error fetching tags:", error);
        return;
      }
      setTags(data || []);
    };

    const fetchLapTimes = async () => {
      const { data, error } = await supabase.from("laptimes").select("*");
      if (error) {
        console.error("Error fetching lap times:", error);
        return;
      }

      processLapTimes(data);
    };

    const subscribeToLapTimes = () => {
      const channel = supabase
        .channel("laptimes")
        .on(
          "postgres_changes",
          { event: "*", schema: "public", table: "laptimes" },
          (payload) => {
            fetchLapTimes(); // Re-fetch data on changes
          }
        )
        .subscribe();

      return () => {
        supabase.removeChannel(channel);
      };
    };

    fetchTags();
    fetchLapTimes();
    const unsubscribe = subscribeToLapTimes();

    return unsubscribe; // Cleanup subscription on unmount
  }, []);

  const processLapTimes = (data) => {
    const newLapTimes = data.map((record) => {
      const baseRow = {
        id: `${record.tag}-${record.location}-${record[`${record.location}.numLaps`]}`,
        tag: record.tag,
        numLaps: record[`${record.location}.numLaps`],
        location: record.location,
        lapTime: record[`${record.location}.lapTime`]?.toFixed(3),
        updated: record[`${record.location}.rtcTime`],
      };

      if (record.location === "L07") {
        let row = {
          ...baseRow,
          h1_corner: (
            record["L07.lapTime"] -
            (record["L07.rtcTime"] - record["L04.rtcTime"]) / 1000
          ).toFixed(3),
          h2_corner: ((record["L07.rtcTime"] - record["L04.rtcTime"]) / 1000).toFixed(3),
          type: "corner_split",
        };
        row["valid"] = (row.h1_corner > 0 && row.h2_corner > 0 && baseRow.lapTime > 0)
        return row;
      } else if (record.location === "L01") {
        let row = {
          ...baseRow,
          m0_m200: (
            record["L01.lapTime"] -
            (record["L01.rtcTime"] - record["L05.rtcTime"]) / 1000 -
            (record["L05.rtcTime"] - record["L02.rtcTime"]) / 1000
          ).toFixed(3),
          m200_m100: ((record["L05.rtcTime"] - record["L02.rtcTime"]) / 1000).toFixed(3),
          m100_m0: ((record["L01.rtcTime"] - record["L05.rtcTime"]) / 1000).toFixed(3),
          type: "sprint_split",
        };
        row["valid"] = (row.m0_m200 > 0 && row.m200_m100 > 0 && row.m100_m0 > 0 && baseRow.lapTime > 0)
        return row;
      } else if (record.location === "L02") {
        let row = {
          ...baseRow,
          h1_straight: (
            record["L02.lapTime"] -
            (record["L02.rtcTime"] - record["L06.rtcTime"]) / 1000
          ).toFixed(3),
          h2_straight: ((record["L02.rtcTime"] - record["L06.rtcTime"]) / 1000).toFixed(3),
          type: "straight_split",        };
        row["valid"] = (row.h1_straight > 0 && row.h2_straight > 0 && baseRow.lapTime > 0)
        return row;
      }
      return baseRow;
    });


    setLapTimes((prev) => {
      const merged = [...newLapTimes, ...prev];
      const uniqueRows = merged.filter(
        (row, index, self) => index === self.findIndex((r) => r.id === row.id)
      );
      uniqueRows.sort((a, b) => b.updated - a.updated);
      console.log("uniqueRows", uniqueRows)
      return uniqueRows;
    });
  };

  const toggleColumn = (column) => {
    setVisibleColumns((prev) => ({ ...prev, [column]: !prev[column] }));
  };

  const toggleTag = (tag) => {
    setSelectedTags((prev) =>
      prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag]
    );
  };

  const toggleCategory = (category) => {
    setSelectedCategories((prev) =>
      prev.includes(category)
        ? prev.filter((c) => c !== category)
        : [...prev, category]
    );
  };

  const selectAllTags = () => {
    setSelectedTags(tags.map((tag) => tag.tag));
  };

  const deselectAllTags = () => {
    setSelectedTags([]);
  };

  const selectAllCategories = () => {
    setSelectedCategories([...new Set(tags.map((tag) => tag.category))]);
  };

  const deselectAllCategories = () => {
    setSelectedCategories([]);
  };

  const handleStart = () => {
    console.log("Start button clicked!");
    // Add your start logic here
  };

  const filteredLapTimes = lapTimes.filter((record) => {
    if (
      selectedTags.length > 0 &&
      !selectedTags.includes(record.tag)
    ) {
      return false;
    }
    if (
      selectedCategories.length > 0 &&
      !tags.some(
        (tag) =>
          tag.tag === record.tag &&
          selectedCategories.includes(tag.category)
      )
    ) {
      return false;
    }
    return true;
  });

  const getTagName = (tag) => {
    const tagObj = tags.find((t) => t.tag === tag);
    return tagObj? tagObj?.name + " (" + tag + ")" : tag; // Use the name if available, otherwise fallback to tag
  };


  return (
    <div>
      <h1>Live MyLaps Results Eddy Merckx Cycling Center, Ghent Belgium</h1>

      {/* Tag Checkboxes */}
      <div>
        <h3>Tags</h3>
        <button onClick={selectAllTags}>Select All</button>
        <button onClick={deselectAllTags}>Deselect All</button>
        {tags.map((tag) => (
          <div key={tag.tag}>
            <label>
              <input
                type="checkbox"
                checked={selectedTags.includes(tag.tag)}
                onChange={() => toggleTag(tag.tag)}
              />
              {getTagName(tag.tag)}
            </label>
          </div>
        ))}
      </div>

      {/* Category Checkboxes */}
      <div>
        <h3>Categories</h3>
        <button onClick={selectAllCategories}>Select All</button>
        <button onClick={deselectAllCategories}>Deselect All</button>
        {Array.from(new Set(tags.map((tag) => tag.category))).map(
          (category) => (
            <div key={category}>
              <label>
                <input
                  type="checkbox"
                  checked={selectedCategories.includes(category)}
                  onChange={() => toggleCategory(category)}
                />
                {category || "Uncategorized"}
              </label>
            </div>
          )
        )}
      </div>

      <div>
        <label>
          <input
            type="checkbox"
            checked={visibleColumns.corner_split}
            onChange={() => toggleColumn("corner_split")}
          />
          Bochten
        </label>
        <label>
          <input
            type="checkbox"
            checked={visibleColumns.straight_split}
            onChange={() => toggleColumn("straight_split")}
          />
          Straights
        </label>
        <label>
          <input
            type="checkbox"
            checked={visibleColumns.sprint_split}
            onChange={() => toggleColumn("sprint_split")}
          />
          Sprint
        </label>
      </div>

      <LapTimesTable tags={tags} lapTimes={filteredLapTimes} visibleColumns={visibleColumns} />
    </div>
  );
};

const LapTimesTable = ({ tags, lapTimes, visibleColumns }) => {
  const getTagName = (tag) => {
    const tagObj = tags.find((t) => t.tag === tag);
    return tagObj? tagObj?.name + " (" + tag + ")" : tag; // Use the name if available, otherwise fallback to tag
  };

  return (
    <table>
      <thead>
        <tr>
          <th>Tag</th>
          <th>Laps</th>
          {visibleColumns.corner_split && <th>H1 (Bocht 1+2)</th>}
          {visibleColumns.corner_split && <th>H2 (Bocht 3+4)</th>}
          {visibleColumns.straight_split && <th>H1 (Home straight)</th>}
          {visibleColumns.straight_split && <th>H2 (Back straight)</th>}
          {visibleColumns.sprint_split && <th>Start &gt; 200m</th>}
          {visibleColumns.sprint_split && <th>200m &gt; 100m</th>}
          {visibleColumns.sprint_split && <th>100m &gt; Finish</th>}
          <th>LapTime</th>
        </tr>
      </thead>
      <tbody>
        {lapTimes.map((record) => (
          "valid" in record && record["valid"] 
          && ((visibleColumns.corner_split && record.h1_corner) 
          || (visibleColumns.straight_split && record.h1_straight)
          || (visibleColumns.sprint_split && record.m0_m200))?
          <tr
            key={record.id}
            style={{

            }}
          >
            <td>{getTagName(record.tag)}</td>
            <td>{record.numLaps}</td>
            {visibleColumns.corner_split && <td>{record.h1_corner}</td>}
            {visibleColumns.corner_split && <td>{record.h2_corner}</td>}
            {visibleColumns.straight_split && <td>{record.h1_straight}</td>}
            {visibleColumns.straight_split && <td>{record.h2_straight}</td>}
            {visibleColumns.sprint_split && <td>{record.m0_m200}</td>}
            {visibleColumns.sprint_split && <td>{record.m200_m100}</td>}
            {visibleColumns.sprint_split && <td>{record.m100_m0}</td>}
            <td>{record.lapTime}</td>
          </tr> : null
        ))}
      </tbody>
    </table>
  );
};

export default Home;
